import styled, { keyframes } from 'styled-components';

import { breakpoint } from 'assets/theme';

const line1 = keyframes`
  from {
    transform: perspective(800px) translate3d(500px, -450px, 0px);
  }
  to {
    transform: perspective(800px) translate3d(-500px, 500px, 0px);
  }
`;

const line2 = keyframes`
  0% {
    transform: perspective(800px) translate3d(100px, -500px, 0px);
    visibility: visible;
  }
  100% {
    transform: perspective(800px) translate3d(-800px, 500px, 0px);
    visibility: visible;
  }
`;

const line3 = keyframes`
  0% {
    transform: perspective(800px) translate3d(800px, -500px, 0px);
    visibility: visible;
  }
  100% {
    transform: perspective(800px) translate3d(-200px, 500px, 0px);
    visibility: visible;
  }
`;

const icon1 = keyframes`
  from {
    opacity: 0;
    transform: perspective(800px) translate3d(0px, 0px, -800px);
  }
  to {
    opacity: 1;
    transform: perspective(800px) translate3d(-800px, -500px, 200px);
  }
`;

const icon2 = keyframes`
  from {
    opacity: 0;
    transform: perspective(800px) translate3d(0px, 0px, -800px);
  }
  to {
    opacity: 1;
    transform: perspective(800px) translate3d(-800px, -380px, 200px);
  }
`;

const icon3 = keyframes`
  from {
    opacity: 0;
    transform: perspective(800px) translate3d(0px, 0px, -800px);
  }
  to {
    opacity: 1;
    transform: perspective(800px) translate3d(-800px, -100px, 200px);
  }
`;

const icon4 = keyframes`
  from {
    opacity: 0;
    transform: perspective(800px) translate3d(0px, 0px, -800px);
  }
  to {
    opacity: 1;
    transform: perspective(800px) translate3d(-800px, 110px, 200px);
  }
`;

const icon5 = keyframes`
  from {
    opacity: 0;
    transform: perspective(800px) translate3d(0px, 0px, -800px);
  }
  to {
    opacity: 1;
    transform: perspective(800px) translate3d(800px, -200px, 200px);
  }
`;

const icon6 = keyframes`
  from {
    opacity: 0;
    transform: perspective(800px) translate3d(0px, 0px, -800px);
  }
  to {
    opacity: 1;
    transform: perspective(800px) translate3d(800px, -20px, 200px);
  }
`;

const icon7 = keyframes`
  from {
    opacity: 0;
    transform: perspective(800px) translate3d(0px, 0px, -800px);
  }
  to {
    opacity: 1;
    transform: perspective(800px) translate3d(800px, 180px, 200px);
  }
`;

const icon8 = keyframes`
  from {
    opacity: 0;
    transform: perspective(800px) translate3d(0px, 0px, -800px);
  }
  to {
    opacity: 1;
    transform: perspective(800px) translate3d(800px, 400px, 200px);
  }
`;

export default styled.div`
  .intro-page {
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    img {
      display: block;
      max-width: 100%;
      max-height: 100%;
    }
    .bg-image {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .bg1 {
      position: absolute;
      width: 4%;
      left: 36%;
      bottom: 27%;
      mix-blend-mode: overlay;
    }
    .bg2 {
      position: absolute;
      width: 32%;
      top: -27%;
      left: -18%;
      mix-blend-mode: overlay;
    }
    .bg3 {
      position: absolute;
      width: 14%;
      top: -13%;
      left: -10%;
      mix-blend-mode: overlay;
    }
    .bg4 {
      position: absolute;
      width: 9%;
      left: 11%;
      top: 19%;
      mix-blend-mode: overlay;
    }
    .bg5 {
      position: absolute;
      width: 4%;
      left: 19%;
      top: 31%;
      mix-blend-mode: overlay;
    }
    .bg6 {
      position: absolute;
      width: 7%;
      top: 41%;
      left: 11%;
      mix-blend-mode: overlay;
    }
    .bg7 {
      position: absolute;
      width: 10%;
      top: 174px;
      left: 65px;
    }
    .bg8 {
      position: absolute;
      width: 27%;
      left: -11%;
      bottom: -18%;
    }
    .bg9 {
      position: absolute;
      width: 27%;
      left: -16%;
      bottom: -30%;
    }
    .bg10 {
      position: absolute;
      width: 54%;
      right: -37%;
      bottom: -53%;
      mix-blend-mode: overlay;
    }
    .bg11 {
      position: absolute;
      width: 31%;
      right: -22%;
      bottom: -28%;
      mix-blend-mode: overlay;
    }
    .bg12 {
      position: absolute;
      width: 14%;
      right: -11%;
      bottom: -10%;
      mix-blend-mode: overlay;
    }
    .bg13 {
      position: absolute;
      width: 14%;
      bottom: -131px;
      right: 172px;
    }
    .bg14 {
      position: absolute;
      width: 6%;
      right: 14%;
      bottom: 30%;
      mix-blend-mode: overlay;
    }
    .bg15 {
      position: absolute;
      width: 4%;
      bottom: 46%;
      right: 7%;
      mix-blend-mode: overlay;
    }
    .bg16 {
      position: absolute;
      width: 4%;
      right: 12%;
      bottom: 53%;
      mix-blend-mode: overlay;
    }
    .bg17 {
      position: absolute;
      width: 2%;
      top: 28%;
      right: 7%;
      mix-blend-mode: overlay;
    }
    .bg18 {
      position: absolute;
      width: 14%;
      top: 25px;
      right: -19px;
    }
    .bg19 {
      position: absolute;
      width: 9%;
      top: 10%;
      right: 31%;
    }
    .line1 {
      width: 97.5px;
      position: absolute;
      animation: ${line1} 8s ease-in 0s infinite forwards;
    }
    .line2 {
      width: 97.5px;
      position: absolute;
      animation: ${line2} 8s ease-in 2s infinite forwards;
      visibility: hidden;
    }
    .line3 {
      width: 97.5px;
      position: absolute;
      animation: ${line3} 8s ease-in 4s infinite forwards;
      visibility: hidden;
    }
    .icon1 {
      opacity: 0;
      position: absolute;
      animation: ${icon1} 8s linear 1s infinite forwards;
    }
    .icon2 {
      opacity: 0;
      position: absolute;
      animation: ${icon2} 8s linear 3s infinite forwards;
    }
    .icon3 {
      opacity: 0;
      position: absolute;
      animation: ${icon3} 8s linear 2s infinite forwards;
    }
    .icon4 {
      opacity: 0;
      position: absolute;
      animation: ${icon4} 8s linear 0s infinite forwards;
    }
    .icon5 {
      opacity: 0;
      position: absolute;
      animation: ${icon5} 8s linear 3s infinite forwards;
    }
    .icon6 {
      opacity: 0;
      position: absolute;
      animation: ${icon6} 8s linear 4s infinite forwards;
    }
    .icon7 {
      opacity: 0;
      position: absolute;
      animation: ${icon7} 8s linear 2s infinite forwards;
    }
    .icon8 {
      opacity: 0;
      position: absolute;
      animation: ${icon8} 8s linear 0s infinite forwards;
    }
    .content {
      position: relative;
      z-index: 2;
      .title-block {
        position: relative;
        border: 5px solid #ffffff;
        width: 453px;
        height: 453px;
        display: flex;
        align-items: flex-end;
        .head-prop-icon {
          position: absolute;
          top: 5%;
          right: -10%;
        }
        h1 {
          font-family: 'Prompt-SemiBold';
          font-size: 70px;
          color: #ffffff;
          padding: 0 80px 40px 60px;
        }
      }
      .text-title2 {
        font-family: 'Prompt-Regular';
        font-size: 30px;
        color: #ffffff;
        line-height: 1.43;
        letter-spacing: 0.3px;
        margin-top: 40px;
      }
      .button-block {
        margin-top: 90px;
      }
    }
  }
  @media (min-width: ${breakpoint.sm}) and (max-width: ${breakpoint.md}) {
    .intro-page {
      height: auto;
      min-height: 100vh;
      .content {
        .title-block {
          border: 5px solid #ffffff;
          width: 200px;
          height: 200px;
          .head-prop-icon {
            position: absolute;
            top: 5%;
            right: -15%;
            width: 45%;
          }
          h1 {
            font-size: 30px;
            padding: 20px;
          }
        }
        .text-title2 {
          font-size: 16px;
          margin-top: 15px;
        }
        .button-block {
          margin-top: 20px;
        }
      }
    }
  }
  @media (max-width: ${breakpoint.sm}) {
    .intro-page {
      height: auto;
      min-height: 100vh;
      .content {
        .title-block {
          border: 5px solid #ffffff;
          width: 250px;
          height: 250px;
          .head-prop-icon {
            position: absolute;
            top: 5%;
            right: -15%;
            width: 45%;
          }
          h1 {
            font-size: 40px;
            padding: 20px;
          }
        }
        .text-title2 {
          font-size: 20px;
          margin-top: 20px;
        }
        .button-block {
          margin-top: 50px;
        }
      }
    }
  }
`;
