import { motion } from 'framer-motion'

import Styled from './styles.js'

const buttonHome = `${process.env.PUBLIC_URL}/assets/images/bt-home.png`
const buttonBack = `${process.env.PUBLIC_URL}/assets/images/bt-back.png`

export default function ButtonMenu({ showBack = true, onGoBack = () => null, onGoHome = () => null }) {
  return (
    <Styled>
      <div className="button-menu-container">
        <motion.div whileTap={{ scale: 0.9 }} className="button-home" onClick={onGoHome}>
          <img src={buttonHome} alt="" />
        </motion.div>
        {showBack && (
          <motion.div whileTap={{ scale: 0.9 }} className="button-back" onClick={onGoBack}>
            <img src={buttonBack} alt="" />
          </motion.div>
        )}
      </div>
    </Styled>
  )
}
