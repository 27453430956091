import styled from 'styled-components';

import { breakpoint } from 'assets/theme';

export default styled.div`
  .question-page {
    position: relative;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .main-menu-block {
      position: absolute;
      bottom: 30px;
      left: 30px;
    }
    .bg-image {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .content {
      transform: scale(0.75);
      max-width: 1327px;
      position: relative;
      z-index: 2;
      margin-top: -6%;
      .topic-block {
        .text-topic {
          font-family: 'Prompt-Regular';
          font-size: 30px;
          line-height: 1.2;
          color: #ffffff;
          text-align: center;
        }
        .line {
          width: 287px;
          height: 2px;
          background-color: #ffffff;
          margin: auto;
          margin-top: 44px;
        }
      }
      .question-block {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 56px;
        .number-block {
          display: flex;
          align-items: flex-end;
          justify-content: center;
          border: 1px solid #ffffff;
          width: 100px;
          height: 100px;
          .text-number {
            padding-bottom: 8px;
            font-family: 'Prompt-Medium';
            font-size: 38px;
            color: #ffffff;
            letter-spacing: 4px;
          }
          .text-total {
            font-family: 'Prompt-Regular';
            font-size: 18px;
            color: #ffffff;
            letter-spacing: 2px;
          }
        }
        .text-question {
          font-family: 'Prompt-Medium';
          font-size: 47px;
          color: #ffffff;
          line-height: 1.21;
          text-align: center;
          height: 115px;
          margin-top: 54px;
          width: 80%;
        }
      }
      .answer-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 80px;
        .answer-block {
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          &:not(:last-child) {
            margin-right: 85px;
          }
          .answer-number-block {
            padding: 7px;
            .answer-number-inner {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 90px;
              height: 90px;
              border: 3px solid;
              span {
                font-family: 'Prompt-Medium';
                font-size: 54px;
                color: #ffffff;
              }
            }
          }
          .answer-text-block {
            margin-top: 40px;
            span {
              font-family: 'Prompt-Regular';
              font-size: 30px;
              color: #ffffff;
            }
          }
          &:nth-child(1) {
            .answer-number-block {
              background-color: #0016ff;
              .answer-number-inner {
                border-color: #0005e1;
              }
            }
          }
          &:nth-child(2) {
            .answer-number-block {
              background-color: #0030ff;
              .answer-number-inner {
                border-color: #006eff;
              }
            }
          }
          &:nth-child(3) {
            .answer-number-block {
              background-color: #0051fe;
              .answer-number-inner {
                border-color: #0093fb;
              }
            }
          }
          &:nth-child(4) {
            .answer-number-block {
              background-color: #0074ff;
              .answer-number-inner {
                border-color: #00abff;
              }
            }
          }
        }
      }
    }
  }
  @media (min-width: ${breakpoint.sm}) and (max-width: ${breakpoint.md}) {
    .question-page {
      height: auto;
      min-height: 100vh;
      .main-menu-block {
        position: absolute;
        bottom: 20px;
        left: 20px;
        z-index: 3;
      }
      .content {
        max-width: auto;
        padding: 50px 40px 80px 40px;
        .topic-block {
          .text-topic {
            font-size: 20px;
          }
          .line {
            width: 400px;
            margin-top: 20px;
          }
        }
        .question-block {
          margin-top: 30px;
          .number-block {
            width: 80px;
            height: 80px;
            .text-number {
              padding-bottom: 8px;
              font-size: 30px;
              letter-spacing: 2px;
            }
            .text-total {
              font-size: 14px;
            }
          }
          .text-question {
            font-size: 24px;
            height: 60px;
            margin-top: 30px;
            width: auto;
          }
        }
        .answer-container {
          margin-top: 30px;
          .answer-block {
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            &:not(:last-child) {
              margin-right: 30px;
            }
            .answer-number-block {
              padding: 7px;
              .answer-number-inner {
                width: 70px;
                height: 70px;
                span {
                  font-size: 34px;
                }
              }
            }
            .answer-text-block {
              margin-top: 15px;
              margin-bottom: 30px;
              span {
                font-size: 24px;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: ${breakpoint.sm}) {
    .question-page {
      height: auto;
      min-height: 100vh;
      .main-menu-block {
        position: absolute;
        bottom: 20px;
        left: 20px;
        z-index: 3;
      }
      .content {
        max-width: auto;
        padding: 50px 20px 80px 20px;
        .topic-block {
          .text-topic {
            font-size: 20px;
          }
          .line {
            width: 250px;
            margin-top: 20px;
          }
        }
        .question-block {
          margin-top: 30px;
          .number-block {
            width: 80px;
            height: 80px;
            .text-number {
              padding-bottom: 8px;
              font-size: 30px;
              letter-spacing: 2px;
            }
            .text-total {
              font-size: 14px;
            }
          }
          .text-question {
            font-size: 24px;
            height: 115px;
            margin-top: 30px;
            width: auto;
          }
        }
        .answer-container {
          display: grid;
          grid-template-columns: auto auto;
          margin-top: 10px;
          .answer-block {
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            &:not(:last-child) {
              margin-right: 0;
            }
            &:nth-child(odd) {
              margin-right: 30px;
            }
            .answer-number-block {
              padding: 7px;
              .answer-number-inner {
                width: 70px;
                height: 70px;
                span {
                  font-size: 34px;
                }
              }
            }
            .answer-text-block {
              margin-top: 15px;
              margin-bottom: 30px;
              span {
                font-size: 20px;
              }
            }
          }
        }
      }
    }
  }
`;

export const ChartStyled = styled.div`
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  .bg-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    position: relative;
    z-index: 2;
    .topic {
      font-family: 'Prompt-Medium';
      font-size: 47px;
      color: #ffffff;
      text-align: center;
      margin-bottom: 50px;
    }
    .chart-block {
      width: 100%;
    }
  }
  .menu-block {
    position: absolute;
    z-index: 2;
    top: 50%;
    right: 50px;
    transform: translateY(-50%) scale(0.7);
    .button-home {
      margin-top: 76px;
    }
  }
  @media (min-width: ${breakpoint.sm}) and (max-width: ${breakpoint.md}) {
    height: 100vh;
    min-height: 100vh;
    overflow: hidden;
    justify-content: flex-start;
    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      padding: 0 20px;
      .topic {
        font-size: 20px;
        margin-bottom: 10px;
      }
      .chart-block {
        overflow: hidden;
        height: 306px;
      }
    }
    .menu-block {
      position: absolute;
      z-index: 2;
      top: 50%;
      right: 30px;
      transform: translateY(-50%);
      img {
        width: 50px;
      }
      .button-home {
        margin-top: 20px;
      }
    }
  }
  @media (max-width: ${breakpoint.sm}) {
    display: block;
    height: auto;
    min-height: 100vh;
    overflow: hidden;
    .content {
      max-width: 100%;
      padding: 40px 20px 0 20px;
      .topic {
        font-size: 24px;
        margin-bottom: 20px;
      }
      .chart-block {
        width: 100%;
      }
    }
    .menu-block {
      position: absolute;
      z-index: 2;
      top: auto;
      bottom: 20px;
      right: 20px;
      transform: none;
      display: flex;
      align-items: center;
      img {
        width: 50px;
      }
      .button-home {
        margin-top: 0;
        margin-left: 15px;
      }
    }
  }
`;
