import { useEffect, useState } from 'react';
import Modal from 'react-modal';

import { Button } from 'components';
import _ from 'lodash';

const bg = `${process.env.PUBLIC_URL}/assets/images/bg-send-mail.jpg`;
const buttonClose = `${process.env.PUBLIC_URL}/assets/images/bt-close-copy.png`;
const iconSuccess = `${process.env.PUBLIC_URL}/assets/images/success-icon.png`;

Modal.setAppElement('#root');

export default function ModalSendEmailForm({
  id,
  isOpen = false,
  onClose = () => null,
}) {
  const [inputValue, setInputValue] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (isOpen) {
      setInputValue(null);
      setShowSuccess(false);
    }
  }, [isOpen]);

  const validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const handleSendEmail = () => {
    if (!inputValue) {
      setError('กรุณากรอกอีเมล');
      return;
    }

    const isEmail = validateEmail(inputValue);
    if (!isEmail) {
      setError('คุณกรอกอีเมลไม่ถูกต้อง');
      return;
    }

    const data = {
      email: inputValue,
    };

    if (!id) {
      let storageAnswers = [];
      const mi_test_answers = localStorage.getItem('mi_test_answers');
      try {
        if (mi_test_answers) {
          storageAnswers = JSON.parse(mi_test_answers);
        }
      } catch (error) {}
      const lastAnswer = storageAnswers[_.size(storageAnswers) - 1];
      lastAnswer.email = inputValue;
      localStorage.setItem('mi_test_answers', JSON.stringify(storageAnswers));
      setShowSuccess(true);
      return;
    }

    const url = `${process.env.REACT_APP_SERVICE_URL}/${process.env.REACT_APP_API_VERSION}/frontend/answers/${id}`;
    fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log('SendEmail result >>', response);
        if (response.status === 'success') {
          setShowSuccess(true);
        }
      })
      .catch((error) => {
        console.log('SendEmail error >>', error);
      });
  };

  const renderContent = () => {
    if (showSuccess)
      return (
        <>
          <img className="icon-success" src={iconSuccess} alt="" />
          <p className="text-status">
            สำเร็จ
            <br /> ข้อมูลถูกส่งไปที่อีเมลของคุณแล้ว
          </p>
        </>
      );
    return (
      <>
        <p className="topic">ส่งผลการทำแบบทดสอบไปที่อีเมลของคุณ</p>
        <input
          className="input-email"
          autoFocus
          onChange={(e) => {
            if (error) {
              setError(null);
            }

            setInputValue(e.target.value);
          }}
        />
        {error && <p className="text-error">{error}</p>}
        <div className="button-block">
          <Button
            style={{
              backgroundImage: 'linear-gradient(#079DFF, #0793FF)',
              width: 295,
            }}
            onClick={handleSendEmail}
          >
            ส่ง
          </Button>
        </div>
      </>
    );
  };
  return (
    <Modal
      isOpen={isOpen}
      closeTimeoutMS={300}
      onRequestClose={onClose}
      className="modal-send-email-form"
    >
      <img className="image-bg image-bg-mobile" src={bg} alt="" />
      <div
        className="image-bg image-bg-desktop"
        style={{
          background: `url(${bg})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
      />
      <img
        className="icon-button-close"
        src={buttonClose}
        alt=""
        onClick={onClose}
      />
      <div className="content">{renderContent()}</div>
    </Modal>
  );
}
