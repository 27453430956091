import Modal from 'react-modal';

const bg = `${process.env.PUBLIC_URL}/assets/images/bg-send-mail.jpg`;
const buttonClose = `${process.env.PUBLIC_URL}/assets/images/bt-close-copy.png`;

// Modal.setAppElement('#root');

export default function ModalAptitideDetail({
  isOpen = false,
  aptitudeData = {},
  onClose = () => null,
}) {
  return (
    <Modal
      isOpen={isOpen}
      closeTimeoutMS={300}
      onRequestClose={onClose}
      className="modal-aptitude-detail"
    >
      <img className="image-bg" src={bg} alt="" />
      <div
        className="image-bg image-bg-desktop"
        style={{
          background: `url(${bg})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
      />
      <img
        className="icon-button-close"
        src={buttonClose}
        alt=""
        onClick={onClose}
      />
      <div className="content">
        <div className="left-block">
          <img
            className="image-icon"
            src={`${process.env.PUBLIC_URL}/${aptitudeData?.icon3x}`}
            alt=""
          />
        </div>
        <div className="right-block">
          <p className="text-title">{`ปัญญาด้าน${aptitudeData?.name}`}</p>
          <p className="text-detail">{aptitudeData?.detail}</p>
        </div>
      </div>
    </Modal>
  );
}
