import { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import { Intro, Description, PersonalInformation, Question } from 'container';

import Styled from './styles.js';

const bg = `${process.env.PUBLIC_URL}/assets/images/bg2.jpg`;

export default function Main() {
  const [step, setStep] = useState(0);
  const [isDescriptionBackward, setIsDescriptionBackward] = useState(false);
  const [isPersonalInfoBackward, setIsPersonalInfoBackward] = useState(false);
  const [personalInfoAnswers, setPersonalInfoAnswers] = useState(null);

  const handleNext = () => {
    setStep(step + 1);
    setIsDescriptionBackward(false);
    setIsPersonalInfoBackward(false);
  };
  const handlePrevious = () => {
    setStep(step - 1);
  };
  const handleGoHome = () => {
    setStep(0);
  };

  const renderStep = () => {
    switch (step) {
      case 0:
        return <Intro onNext={handleNext} />;
      case 1:
        return (
          <AnimatePresence>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <Description
                isBackward={isDescriptionBackward}
                onNext={handleNext}
                onPrevious={handlePrevious}
                onGoHome={handleGoHome}
              />
            </motion.div>
          </AnimatePresence>
        );
      case 2:
        return (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <PersonalInformation
              isBackward={isPersonalInfoBackward}
              onNext={(answers) => {
                handleNext();
                setPersonalInfoAnswers(answers);
              }}
              onPrevious={() => {
                handlePrevious();
                setIsDescriptionBackward(true);
              }}
              onGoHome={handleGoHome}
            />
          </motion.div>
        );
      case 3:
        return (
          <AnimatePresence>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <Question
                personalInfoAnswers={personalInfoAnswers}
                onNext={handleNext}
                onPrevious={() => {
                  handlePrevious();
                  setIsPersonalInfoBackward(true);
                }}
                onGoHome={handleGoHome}
              />
            </motion.div>
          </AnimatePresence>
        );

      default:
        return null;
    }
  };
  return (
    <Styled>
      <img className="main-bg" src={bg} alt="" />
      {renderStep()}
    </Styled>
  );
}
