import { useEffect, useState } from 'react';
import _ from 'lodash';
import { motion } from 'framer-motion';

import {
  Chart,
  ButtonMenu,
  ModalSendEmailForm,
  ModalConfirm,
} from 'components';
import { shuffle } from 'utils';

// import testDataAnswers from 'assets/testDataAnswers';

import Styled, { ChartStyled } from './styles.js';

const bg = `${process.env.PUBLIC_URL}/assets/images/bg2.jpg`;
const buttonHome = `${process.env.PUBLIC_URL}/assets/images/bt-home-blue.png`;
const buttonSendEmail = `${process.env.PUBLIC_URL}/assets/images/bt-send-email.png`;

const answerScores = [
  { number: 0, name: 'ไม่เคย' },
  { number: 1, name: 'น้อย' },
  { number: 2, name: 'ใช่' },
  { number: 3, name: 'ใช่ที่สุด' },
];

export default function Question({
  personalInfoAnswers,
  onPrevious = () => null,
  onGoHome = () => null,
}) {
  const [questionIndex, setQuestionIndex] = useState(0);
  const [questions, setQuestions] = useState([]);

  // FOR Test
  // const [isShowChart, setIsShowChart] = useState(true);
  // const [chartData, setChartData] = useState(testDataAnswers);

  const [isShowChart, setIsShowChart] = useState(false);
  const [chartData, setChartData] = useState([]);

  const [isOpenSendEmailForm, setIsOpenSendEmailForm] = useState(false);
  const [submittedId, setSubmittedId] = useState(null);
  const [isOpenConfirmModal, setOpenConfirmModal] = useState(false);

  const question = questions[questionIndex];

  useEffect(() => {
    const init = () => {
      fetch(
        `${process.env.PUBLIC_URL}/assets/data/questions.json`
        // `${process.env.REACT_APP_SERVICE_URL}/${process.env.REACT_APP_API_VERSION}/frontend/questions`
      )
        .then((response) => response.json())
        .then((data) => {
          const newQuestionSorted = shuffle(data.result);
          setQuestions(newQuestionSorted);
        });
    };

    init();
  }, []);

  const submitQuestion = () => {
    const {
      age,
      gender,
      grade,
      address,
      subject,
      career,
    } = personalInfoAnswers;

    const data = {
      age,
      gender,
      grade,
      address,
      subject,
      career,
      answers: questions.map((question) => {
        return {
          question_id: question.id,
          answer: question.answer,
        };
      }),
    };

    const url = `${process.env.REACT_APP_SERVICE_URL}/${process.env.REACT_APP_API_VERSION}/frontend/answers`;
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((response) => {
        setSubmittedId(response.result.id);
      })
      .catch(() => {
        let storageAnswers = [];
        const mi_test_answers = localStorage.getItem('mi_test_answers');
        try {
          if (mi_test_answers) {
            storageAnswers = JSON.parse(mi_test_answers);
          }
        } catch (error) {}
        const newData = [...storageAnswers, data];
        localStorage.setItem('mi_test_answers', JSON.stringify(newData));
      });
    return questions;
  };

  const handleClickAnswer = async (answerNo) => {
    questions[questionIndex].answer = answerNo;
    setQuestions(questions);
    const nextQuestionIndex = questionIndex + 1;
    if (nextQuestionIndex === questions.length) {
      const data = await submitQuestion();
      setIsShowChart(true);
      setChartData(data);
      return;
    }
    setQuestionIndex(nextQuestionIndex);
  };

  if (isShowChart) {
    return (
      <ChartStyled>
        <img className="bg-image" src={bg} alt="" />
        <div className="content">
          <p className="topic">ผลการทำแบบทดสอบของคุณ</p>
          <div className="chart-block">
            <Chart data={chartData} />
          </div>
        </div>
        <div className="menu-block">
          <div
            className="button-send-mail"
            onClick={() => setIsOpenSendEmailForm(true)}
          >
            <img src={buttonSendEmail} alt="" />
          </div>
          <div className="button-home" onClick={onGoHome}>
            <img src={buttonHome} alt="" />
          </div>
        </div>
        <ModalSendEmailForm
          isOpen={isOpenSendEmailForm}
          id={submittedId}
          onClose={() => setIsOpenSendEmailForm(false)}
        />
      </ChartStyled>
    );
  }

  return (
    <Styled>
      <div className="question-page">
        <img className="bg-image" src={bg} alt="" />
        <div className="content">
          <div className="topic-block">
            <p className="text-topic">เลือกคำตอบที่ตรงกับตัวคุณมากที่สุด</p>
            <div className="line" />
          </div>
          <div className="question-block">
            <div className="number-block">
              <span className="text-number">
                {questionIndex + 1}
                <span className="text-total">/{_.size(questions)}</span>
              </span>
            </div>
            <p className="text-question">{question?.name}</p>
          </div>
          <div className="answer-container">
            {answerScores.map((answer, index) => {
              return (
                <motion.div
                  whileTap={{ scale: 0.9 }}
                  className="answer-block"
                  key={index}
                  onClick={() => handleClickAnswer(answer.number)}
                >
                  <div className="answer-number-block">
                    <div className="answer-number-inner">
                      <span>{answer.number}</span>
                    </div>
                  </div>
                  <div className="answer-text-block">
                    <span>{answer.name}</span>
                  </div>
                </motion.div>
              );
            })}
          </div>
        </div>
        <div className="main-menu-block">
          <ButtonMenu
            onGoBack={() => {
              if (questionIndex === 0) {
                onPrevious();
              } else {
                setQuestionIndex(questionIndex - 1);
              }
            }}
            onGoHome={() => setOpenConfirmModal(true)}
          />
        </div>
      </div>
      <ModalConfirm
        isOpen={isOpenConfirmModal}
        onClose={() => setOpenConfirmModal(false)}
        onConfirm={onGoHome}
      />
    </Styled>
  );
}
