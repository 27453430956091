import styled from 'styled-components';

import { breakpoint } from 'assets/theme';

export default styled.div`
  .button-menu-container {
    transform: scale(0.75);
    display: flex;
    .button-back {
      margin-left: 30px;
    }
  }
  @media (min-width: ${breakpoint.sm}) and (max-width: ${breakpoint.md}) {
  }
  @media (max-width: ${breakpoint.md}) {
    .button-menu-container {
      transform: scale(1);
      img {
        width: 50px;
      }
      .button-back {
        margin-left: 20px;
      }
    }
  }
`;
