import { motion } from 'framer-motion';

import Styled from './styles.js';

export default function Button({ children, ghost = false, ...props }) {
  return (
    <Styled ghost={ghost}>
      <motion.button whileTap={{ scale: 0.9 }} {...props}>
        <div className="inner-block">{children}</div>
      </motion.button>
    </Styled>
  );
}
