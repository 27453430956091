import styled from 'styled-components';

import { breakpoint } from 'assets/theme';

export default styled.div`
  .personal-information-page {
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: center;
    .main-menu-block {
      position: absolute;
      bottom: 30px;
      left: 30px;
    }
    .bg-image {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .content {
      transform: scale(0.75);
      max-width: 1327px;
      position: relative;
      z-index: 2;
      .topic-block {
        .text-topic {
          font-family: 'Prompt-Regular';
          font-size: 30px;
          color: #ffffff;
          text-align: center;
        }
        .line {
          width: 287px;
          height: 2px;
          background-color: #ffffff;
          margin: auto;
          margin-top: 44px;
        }
      }
      .text-question {
        font-family: 'Prompt-Medium';
        font-size: 47px;
        color: #ffffff;
        line-height: 1.21;
        text-align: center;
        margin-top: 40px;
      }
      .choices-container {
        display: grid;
        grid-template-columns: auto auto;
        margin-top: 80px;
        .choice-block {
          &:not(:last-child) {
            margin-bottom: 30px;
          }
          &:nth-child(odd) {
            margin-right: 60px;
          }
          .inner-block {
            border-color: #00e5ff;
          }
          &:nth-child(1) {
            button {
              background-image: linear-gradient(#04C6FF, #06A0FF);
            }
          }
          &:nth-child(2) {
            button {
              background-image: linear-gradient(#06B4FF, #069CFF);
            }
          }
          &:nth-child(3) {
            button {
              background-image: linear-gradient(#06A8FF, #088EFF);
            }
          }
          &:nth-child(4) {
            button {
              background-image: linear-gradient(#069AFF, #087AFF);
            }
          }
          &:nth-child(5) {
            button {
              background-image: linear-gradient(#0882FF, #0871FF);
            }
          }
        }
      }
    }
  }
  @media (min-width: ${breakpoint.sm}) and (max-width: ${breakpoint.md}) {
    .personal-information-page {
      height: auto;
      min-height: 100vh;
      .main-menu-block {
        position: absolute;
        bottom: 20px;
        left: 20px;
        z-index: 3;
      }
      .content {
        margin-top: 0;
        padding: 40px 20px 100px 20px;
        .topic-block {
          .text-topic {
            font-size: 20px;
          }
          .line {
            width: 75%;
            margin-top: 20px;
          }
        }
        .text-question {
          font-size: 24px;
          margin-top: 30px;
        }
        .choices-container {
          display: grid;
          grid-template-columns: auto auto;
          margin-top: 30px;
          text-align: center;
          .choice-block {
            button {
              min-width: 90%;
              font-size: 18px !important;
            }
            &:not(:last-child) {
              margin-bottom: 20px;
            }
            &:nth-child(odd) {
              margin-right: 20px;
            }
          }
        }
      }
    }
  }
  @media (max-width: ${breakpoint.sm}) {
    .personal-information-page {
      height: auto;
      min-height: 100vh;
      .main-menu-block {
        position: absolute;
        bottom: 20px;
        left: 20px;
        z-index: 3;
      }
      .content {
        width: 100%;
        margin-top: 0;
        padding: 50px 20px 120px 20px;
        .topic-block {
          .text-topic {
            font-size: 20px;
          }
          .line {
            width: 250px;
            margin-top: 20px;
          }
        }
        .text-question {
          font-size: 24px;
          height: 60px;
          margin-top: 40px;
        }
        .choices-container {
          display: grid;
          grid-template-columns: auto;
          margin-top: 20px;
          text-align: center;
          .choice-block {
            button {
              min-width: 90%;
              font-size: 20px !important;
            }
            &:not(:last-child) {
              margin-bottom: 15px;
            }
            &:nth-child(odd) {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
`;
