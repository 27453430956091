import styled from 'styled-components';

import {breakpoint} from 'assets/theme';

export default styled.div`
  .highcharts-container {
    path {
      opacity: 1 !important;
    }
    .highcharts-background {
      fill: transparent;
    }
    .highcharts-credits {
      display: none;
    }
    .highcharts-label {
      opacity: 1 !important;
      span {
        white-space: normal;
      }
    }
  }
  @media (min-width: ${breakpoint.sm}) and (max-width: ${breakpoint.md}) {
    transform: scale(0.37);
    transform-origin: top;
  }
  @media (max-width: ${breakpoint.sm}) {
    .description-block {
      margin-top: 8px;
      .dot-block {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        .dot-color {
          width: 12px;
          height: 12px;
          border-radius: 100%;
          margin-right: 10px;
        }
        .dot-name {
          font-family: 'Prompt-Regular';
          font-size: 14px;
          color: #ffffff;
        }
      }
    }
  }
`;
