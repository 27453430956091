import { useEffect, useState } from 'react';
import _ from 'lodash';
import { motion } from 'framer-motion';

import { Button, ButtonMenu } from 'components';

import Styled from './styles.js';

const bg = `${process.env.PUBLIC_URL}/assets/images/bg2-2.jpg`;
const headpropIcon = `${process.env.PUBLIC_URL}/assets/images/head-prop.png`;

const aptitudes = [
  {
    name: 'ด้านร่างกายและ\nการเคลื่อนไหว',
    icon: `${process.env.PUBLIC_URL}/assets/images/icons/aptitude/icon-1.png`,
  },
  {
    name: 'ด้านตรรกะศาสตร์\nและคณิตศาสตร์',
    icon: `${process.env.PUBLIC_URL}/assets/images/icons/aptitude/icon-5.png`,
  },
  {
    name: 'ด้านภาษา',
    icon: `${process.env.PUBLIC_URL}/assets/images/icons/aptitude/icon-2.png`,
  },
  {
    name: 'ด้านธรรมชาติวิทยา',
    icon: `${process.env.PUBLIC_URL}/assets/images/icons/aptitude/icon-6.png`,
  },
  {
    name: 'ด้านดนตรี',
    icon: `${process.env.PUBLIC_URL}/assets/images/icons/aptitude/icon-3.png`,
  },
  {
    name: 'ด้านมิติสัมพันธ์',
    icon: `${process.env.PUBLIC_URL}/assets/images/icons/aptitude/icon-7.png`,
  },
  {
    name: 'ด้านมนุษยสัมพันธ์',
    icon: `${process.env.PUBLIC_URL}/assets/images/icons/aptitude/icon-4.png`,
  },
  {
    name: 'ด้านความเข้าใจตนเอง',
    icon: `${process.env.PUBLIC_URL}/assets/images/icons/aptitude/icon-8.png`,
  },
];

export default function Description({
  isBackward = false,
  onNext = () => null,
  onPrevious = () => null,
  onGoHome = () => null,
}) {
  const [step, setStep] = useState(0);

  useEffect(() => {
    if (isBackward) {
      setStep(1);
    }
  }, [isBackward]);

  const renderStepDetail = () => {
    switch (step) {
      case 0:
        return (
          <>
            <motion.p
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0, duration: 0.6 }}
              className="text-description"
            >
              คนเรามีความถนัดที่ต่างกัน นำไปสู่อาชีพที่แตกต่างกัน แบบทดสอบนี้
              จะช่วยทำให้คุณเข้าใจศักยภาพของตนเองและวางแผนอาชีพในอนาคตได้
              ตรงตามความถนัดมากยิ่งขึ้น
            </motion.p>
            <div className="aptitude-container">
              {_.map(aptitudes, (aptitude, index) => {
                return (
                  <motion.div
                    initial={{ opacity: 0, x: 20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: 0.3 + index * 0.1, duration: 0.6 }}
                    className="aptitude-block"
                    key={index}
                  >
                    <div className="icon-block">
                      <img src={aptitude.icon} alt={aptitude.name} />
                    </div>
                    <div className="name-block">{aptitude.name}</div>
                  </motion.div>
                );
              })}
            </div>
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ delay: 1, duration: 0.6 }}
              className="button-block"
            >
              <Button onClick={() => setStep(step + 1)}>ถัดไป</Button>
            </motion.div>
          </>
        );
      case 1:
        return (
          <>
            <motion.p
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0, duration: 0.6 }}
              className="topic-step2"
            >
              วิธีทำแบบสอบถาม
            </motion.p>
            <motion.p
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.3, duration: 0.6 }}
              className="text-description"
            >
              แบบทดสอบนี้มีคำถามทั้งหมด 40 ข้อ ผู้ทำแบบทดสอบต้องเลือกคำตอบ
              ที่ใกล้เคียงกับความสนใจของตนเองมากที่สุด จาก 0 - 3 คะแนน
              <br />
              <br />
              โดย 0 = ไม่เลย, 1 = น้อย, 2 = ใช่, 3 = ใช่ที่สุด
              <br />
              <br />
              ข้อมูลจะไม่ถูกบันทึกและทำการวิเคราะห์จนกว่าผู้ทำแบบทดสอบจะทำ
              แบบทดสอบเสร็จสิ้น และทีมงานจะทำการเก็บข้อมูล อายุ เพศ และ
              ผลการทดสอบของคุณเพื่อประโยชน์ในด้านการศึกษาโดยไม่ระบุตัวตน
              ผู้ทำแบบสอบถาม
            </motion.p>
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ delay: 0.6, duration: 0.6 }}
              className="button-block"
            >
              <Button onClick={onNext}>เริ่ม</Button>
            </motion.div>
          </>
        );
      default:
        return null;
    }
  };
  return (
    <Styled>
      <div className="description-page">
        <img className="bg-image" src={bg} alt="" />
        <div className="content">
          <div className="left-block">
            <motion.div
              initial={{ scaleX: 0 }}
              animate={{ scaleX: 1 }}
              transition={{ delay: 0, duration: 0.6 }}
              className="title-block"
            >
              <motion.img
                initial={{ scaleX: 0 }}
                animate={{ scaleX: 1 }}
                transition={{ delay: 0.6, duration: 0.6 }}
                className="head-prop-icon"
                src={headpropIcon}
                alt=""
              />
              <motion.h1
                initial={{ x: 150, y: -150, scale: 0 }}
                animate={{ x: 0, y: 0, scale: 1 }}
                transition={{ delay: 0.7, duration: 0.6 }}
              >
                แบบทดสอบ
                <br />
                พหุปัญญา
                <br />8 ด้าน
              </motion.h1>
            </motion.div>
            <motion.p
              initial={{ y: -150, scale: 0 }}
              animate={{ y: 0, scale: 1 }}
              transition={{ delay: 0.8, duration: 0.6 }}
              className="text-title2"
            >
              Multiple
              <br />
              Intelligences
              <br />
              Test
            </motion.p>
          </div>
          <div className="right-block">{renderStepDetail()}</div>
        </div>
        <div className="main-menu-block">
          <ButtonMenu
            onGoBack={() => {
              if (step === 0) {
                onPrevious();
              } else {
                setStep(step - 1);
              }
            }}
            onGoHome={onGoHome}
          />
        </div>
      </div>
    </Styled>
  );
}
