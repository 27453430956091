import { useEffect, useState } from 'react';
import axios from 'axios';

export const useProcess = () => {
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    const interval = setInterval(async () => {
      await doSubmitItem();
    }, 1000 * 60 * 10);

    return () => clearInterval(interval);
  }, []); // eslint-disable-line

  const handleOnline = async () => {
    await doSubmitItem();
  };

  const handleOffline = async () => {};

  const getItems = () => {
    let items = [];
    const mi_test_answers = localStorage.getItem('mi_test_answers');
    try {
      if (mi_test_answers) {
        items = JSON.parse(mi_test_answers);
      }
    } catch (error) {}
    return items;
  };

  const removeFirstItems = () => {
    const items = getItems();
    if (items.length < 1) return;
    const newItems = [...items];
    newItems.shift();
    localStorage.setItem('mi_test_answers', JSON.stringify(newItems));
  };

  const doSubmitItem = async () => {
    const items = getItems();
    if (items.length < 1) return;
    const item = items[0];
    await submitItem(item);
  };

  const submit = async (item) => {
    const url = `${process.env.REACT_APP_SERVICE_URL}/${process.env.REACT_APP_API_VERSION}/frontend/answers`;
    axios({
      method: 'post',
      url,
      data: item,
      timeout: 5000,
    })
      .then(async (res) => {
        removeFirstItems();
        await doSubmitItem();
      })
      .catch((err) => {
        console.log('err', err);
      })
      .finally(() => {
        console.log('finally');
      });
  };

  const submitItem = async (item) => {
    if (processing) return;
    setProcessing(true);
    await submit(item);
    setProcessing(false);
  };

  return null;
};
