import {useState} from 'react';
import Highcharts from 'highcharts';
import highchartsVariablepie from 'highcharts/modules/variable-pie';
import HighchartsReact from 'highcharts-react-official';
import _ from 'lodash';

import {ModalAptitideDetail} from 'components';

import {useOrientation} from 'hooks/orientation';

import Styled from './styles.js';

highchartsVariablepie(Highcharts);

export default function Chart({data}) {
  const {screenWidth} = useOrientation();

  const [isOpenDetail, setOpenDetail] = useState(false);
  const [selectedDetailKey, setSelectedDetailKey] = useState(null);

  const sortedData = _.sortBy(data, 'id');
  const dataGroups = _.groupBy(sortedData, 'aptitude.name');

  let i = -1;
  const dataOption = _.map(dataGroups, (group, key) => {
    i++;

    let countAnswer = _.sumBy(group, 'answer');
    if (countAnswer <= 0) return null;

    const upSize = 0;
    const countAnswerUpSize = countAnswer + upSize;
    let dataLabelY = -15;
    let dataLabelX = 10;
    let dataDistance = 30;

    if (countAnswerUpSize < 3 + upSize) {
      dataDistance = 100;
    }
    if (i === 0) {
      dataLabelY = -25;
      dataLabelX = 25;
      if (countAnswerUpSize < 4 + upSize) {
        dataLabelY = -70;
      }
    }
    if (i === 7) {
      dataLabelY = -60;
      dataLabelX = 90;
      if (countAnswerUpSize > 5 + upSize) {
        dataLabelY = -15;
        dataLabelX = 50;
      }
      if (countAnswerUpSize < 4 + upSize) {
        dataLabelY = -70;
      }
    }

    return {
      index: i,
      legendIndex: i,
      name: `ด้าน${key}`,
      y: 20,
      color: group[0].aptitude.color,
      z: countAnswerUpSize,
      events: {
        click: () => {
          setOpenDetail(true);
          setSelectedDetailKey(key);
        },
      },
      dataLabels: {
        distance: dataDistance,
        connectorWidth: 0,
        shadow: true,
        y: dataLabelY,
        x: dataLabelX,
        useHTML: true,
        format: `<div style="display: flex; align-items: center; width: 300px">
        <img src="${process.env.PUBLIC_URL}/${group[0].aptitude.icon}" style="width: 65px; margin-right: 20px"/>
        <span style="font-family:'Prompt-Light'; font-size: 24px; color: #ffffff; letter-spacing: 1.8px; line-height: 1.3; white-space: pre-wrap;">ด้าน${key}</span>
        </div>`,
      },
    };
  });

  let options = {
    chart: {
      type: 'variablepie',
    },
    title: {
      text: '',
    },
    tooltip: {
      enabled: false,
    },
    series: [
      {
        minPointSize: 20,
        zMin: 0,
        colorByPoint: true,
        shadow: true,
        innerSize: '15%',
        name: 'aptitudes',
        borderWidth: 1,
        // borderColor: '#000000',
        data: dataOption,
      },
    ],
  };

  const selectedData = _.get(dataGroups, selectedDetailKey);
  const aptitudeData = _.get(selectedData, '0.aptitude');

  if (screenWidth > 575.98) {
    options = {
      ...options,
      chart: {
        ...options.chart,
        width: 1600,
        height: 830,
      },
    };
  }
  if (screenWidth < 575.98) {
    options = {
      ...options,
      chart: {
        ...options.chart,
        // type: 'pie',
        width: screenWidth - 40,
        height: '100%',
        margin: [0, 0, 0, 0],
        spacingTop: 0,
        spacingBottom: 0,
        spacingLeft: 0,
        spacingRight: 0,
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false,
            // distance: -70,
          },
          showInLegend: true,
        },
      },
      series: [
        {
          ...options.series[0],
          data: _.map(options.series[0].data, (item) => {
            return {
              ...item,
              dataLabels: {
                ...item.dataLabels,
                enabled: false,
              },
            };
          }),
        },
      ],
    };
  }

  return (
    <Styled>
      <HighchartsReact key={options} highcharts={Highcharts} options={options} />
      {screenWidth < 575.98 && (
        <div className="description-block">
          {_.map(options.series[0].data, (item, index) => {
            return (
              <div className="dot-block" key={index}>
                <div className="dot-color" style={{backgroundColor: item.color}} />
                <div className="dot-name">{item.name}</div>
              </div>
            );
          })}
        </div>
      )}

      <ModalAptitideDetail
        isOpen={isOpenDetail}
        onClose={() => setOpenDetail(false)}
        aptitudeData={aptitudeData}
      />
    </Styled>
  );
}
