import styled from 'styled-components';

export default styled.div`
  position: relative;
  .main-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .main-menu-block {
    position: absolute;
    bottom: 73px;
    left: 85px;
  }
`;
