import { useState, useEffect } from 'react';
import _ from 'lodash';

import { Button, ButtonMenu } from 'components';

import Styled from './styles.js';

const bg = `${process.env.PUBLIC_URL}/assets/images/bg2.jpg`;

const questions = [
  {
    key: 'age',
    name: 'กรุณาเลือกอายุของคุณ',
    choices: [
      { name: 'น้อยกว่า 13 ปี' },
      { name: '13 - 15 ปี' },
      { name: '16 - 18 ปี' },
      { name: '19 - 22 ปี' },
      { name: 'มากกว่า 22 ปี' },
    ],
  },
  {
    key: 'gender',
    name: 'กรุณาเลือกเพศของคุณ',
    choices: [{ name: 'ชาย' }, { name: 'หญิง' }, { name: 'อื่น ๆ' }],
  },
  {
    key: 'grade',
    name: 'กรุณาเลือกระดับชั้นของคุณ',
    choices: [
      { name: 'ประถมศึกษาตอนต้น (ป.1-ป.3)' },
      { name: 'ประถมศึกษาตอนปลาย (ป.4-ป.6)' },
      { name: 'มัธยมศึกษาตอนต้น (ม.1-ม.3)' },
      { name: 'มัธยมศึกษาตอนปลาย (ม.4-ม.6)' },
      { name: 'อนุปริญญา (ปวช.-ปวส.)' },
      { name: 'ปริญญาตรี' },
      { name: 'สูงกว่าปริญญาตรี' },
    ],
  },
  {
    key: 'address',
    name: 'กรุณาเลือกพื้นที่อยู่อาศัยของคุณ',
    choices: [
      { name: 'ภาคเหนือ' },
      { name: 'ภาคกลาง' },
      { name: 'ภาคใต้' },
      { name: 'ภาคตะวันตก' },
      { name: 'ภาคตะวันออก' },
      { name: 'ภาคตะวันออกเฉียงเหนือ' },
      { name: 'กรุงเทพฯ และปริมญฑล' },
    ],
  },
  {
    key: 'subject',
    name: 'กรุณาเลือกวิชาที่คุณชื่นชอบ',
    choices: [
      { name: 'ภาษาไทย' },
      { name: 'คณิตศาสตร์' },
      { name: 'วิทยาศาสตร์' },
      { name: 'สังคม ศาสนา วัฒนธรรม' },
      { name: 'สุขศึกษา และพลศึกษา' },
      { name: 'ศิลปะ' },
      { name: 'การงานอาชีพ และเทคโนโลยี' },
      { name: 'ภาษาต่างประเทศ' },
    ],
  },
  {
    key: 'career',
    name: 'กรุณาเลือกอาชีพผู้ปกครองของคุณ',
    choices: [
      { name: 'ข้าราชการ/รัฐวิสาหกิจ' },
      {
        name: 'ค้าขาย/ธุรกิจส่วนตัว/เจ้าของกิจการ',
        buttonStyle: { fontSize: 26 },
      },
      { name: 'เกษตรกรรม/ประมง/ปศุสัตว์' },
      { name: 'พนักงานบริษัทเอกชน' },
      { name: 'เกษียณ/ว่างงาน' },
    ],
  },
];

export default function PersonalInformation({
  isBackward = false,
  onNext = () => null,
  onPrevious = () => null,
  onGoHome = () => null,
}) {
  const [step, setStep] = useState(0);
  const [answers, setAnswers] = useState(questions);

  useEffect(() => {
    if (isBackward) {
      setStep(_.size(questions) - 1);
    }
  }, [isBackward]);

  const question = questions[step];

  return (
    <Styled>
      <div className="personal-information-page">
        <img className="bg-image" src={bg} alt="" />
        <div className="content">
          <div className="topic-block">
            <p className="text-topic">ข้อมูลเบื้องต้นของผู้ทำแบบสอบถาม</p>
            <div className="line" />
          </div>
          <p className="text-question">{question?.name}</p>
          <div className="choices-container">
            {_.map(question?.choices, (item, index) => {
              const name = item?.name;
              return (
                <div className="choice-block" key={index}>
                  <Button
                    style={item?.buttonStyle}
                    onClick={() => {
                      question.answer = name;
                      setAnswers(questions);

                      if (step === _.size(questions) - 1) {
                        const dataAnswers = _.reduce(
                          answers,
                          (result, value, key) => {
                            return {
                              ...result,
                              [value.key]: value.answer,
                            };
                          },
                          {}
                        );
                        onNext(dataAnswers);
                      } else {
                        setStep(step + 1);
                      }
                    }}
                  >
                    {name}
                  </Button>
                </div>
              );
            })}
          </div>
        </div>
        <div className="main-menu-block">
          <ButtonMenu
            onGoBack={() => {
              if (step === 0) {
                onPrevious();
              } else {
                setStep(step - 1);
              }
            }}
            onGoHome={onGoHome}
          />
        </div>
      </div>
    </Styled>
  );
}
