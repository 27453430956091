import { createGlobalStyle } from 'styled-components';

import { breakpoint } from 'assets/theme';

const GlobalStyle = createGlobalStyle`
@font-face {
  font-family: 'Prompt-Light';
  src: url('/assets/fonts/Prompt-Light.ttf');
}
@font-face {
  font-family: 'Prompt-Regular';
  src: url('/assets/fonts/Prompt-Regular.ttf');
}
@font-face {
  font-family: 'Prompt-Medium';
  src: url('/assets/fonts/Prompt-Medium.ttf');
}
@font-face {
  font-family: 'Prompt-SemiBold';
  src: url('/assets/fonts/Prompt-SemiBold.ttf');
}
* {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: -moz-none;
  -o-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}
input {
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -o-user-select: text;
  user-select: text;
}

::selection,
::-moz-selection {
  color: none;
  background: none;
}
body {
  font-family: 'Prompt-Regular';
}
.content{
  transform: scale(0.7);
  @media (max-width: ${breakpoint.md}){
    transform: scale(1) !important;
  }
}
.ReactModal__Overlay {
  opacity: 0;
  z-index: 1111;
  transition: all 300ms;
}
.ReactModal__Overlay--after-open {
  opacity: 1;
}
.ReactModal__Overlay--before-close {
  opacity: 0;
}
.ReactModal__Content {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background: #000000;
  width: 100%;
  height: 100%;
  .image-bg {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}
.modal-send-email-form {
  .icon-button-close {
    position: absolute;
    top: 30px;
    left: 30px;
    z-index: 2;
    transform: scale(0.7);
  }
  .image-bg-mobile{
    display: none;
  }
  .image-bg-desktop{
    display: block;
  }
  .content {
    position: relative;
    height: 100%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .topic {
      font-family: 'Prompt-SemiBold';
      font-size: 47px;
      letter-spacing: 0.75px;
      color: #ffffff;
      text-align: center;
    }
    .input-email {
      width: 864px;
      height: 86px;
      background-color: #000653;
      font-family: 'Prompt-Regular';
      font-size: 35px;
      color: #ffffff;
      text-align: center;
      /* text-transform: lowercase; */
      margin-top: 66px;
    }
    .text-error{
      font-family: 'Prompt-Regular';
      font-size: 28px;
      color: red;
      text-align: center;
      margin-top: 10px;
    }
    .button-block {
      margin-top: 100px;
    }
    .text-status {
      font-family: 'Prompt-Medium';
      font-size: 47px;
      line-height: 1.3;
      letter-spacing: 0.94px;
      color: #ffffff;
      text-align: center;
      margin-top: 50px;
    }
  }
  @media (min-width: ${breakpoint.sm}) and (max-width: ${breakpoint.md}) {
    .icon-button-close {
      position: absolute;
      top: 15px;
      left: 15px;
      width: 40px;
    }
    .image-bg-desktop{
      display: block;
    }
    .image-bg-mobile{
      display: none;
    }
    .content {
      max-width: auto;
      padding: 0 20px 0 20px;
      flex-direction: column;
      .topic {
        font-size: 20px;
      }
      .input-email {
        width: 70%;
        height: 45px;
        font-size: 20px;
        margin-top: 30px;
      }
      .text-error{
        font-size: 18px;
      }
      .button-block {
        margin-top: 30px;
      }
      .icon-success{
        width: 18%;
      }
      .text-status {
        font-size: 24px;
        margin-top: 30px;
      }
    }
  }
  @media (max-width: ${breakpoint.sm}) {
    .image-bg-desktop{
      display: none;
    }
    .image-bg-desktop{
      display: block;
    }
    .icon-button-close {
      position: absolute;
      top: 20px;
      left: 20px;
      width: 50px;
    }
    .content {
      max-width: auto;
      padding: 0 20px 0 20px;
      flex-direction: column;
      .topic {
        font-size: 22px;
      }
      .input-email {
        width: 90%;
        height: 45px;
        font-size: 20px;
        margin-top: 40px;
      }
      .text-error{
        font-size: 18px;
      }
      .button-block {
        margin-top: 30px;
      }
      .icon-success{
        width: 45%;
      }
      .text-status {
        font-size: 24px;
        margin-top: 50px;
      }
    }
  }
}
.modal-aptitude-detail {
  .icon-button-close {
    position: absolute;
    top: 30px;
    left: 30px;
    z-index: 2;
    transform: scale(0.7);
  }
  .image-bg-mobile{
    display: none;
  }
  .image-bg-desktop{
    display: block;
  }
  .content {
    max-width: 1280px;
    margin: auto;
    position: relative;
    height: 100%;
    z-index: 1;
    display: flex;
    justify-content: center;
    padding-top: 8%;
    transform: scale(0.7);
    .left-block {
      margin-right: 170px;
      .image-icon {
        width: 184px;
        box-shadow: 10.2px 18.4px 24px 0 rgba(0, 15, 54, 0.38);
      }
    }
    .right-block {
      .text-title {
        font-family: 'Prompt-SemiBold';
        font-size: 40px;
        line-height: 1.3;
        letter-spacing: 0.88px;
        color: #ffffff;
      }
      .text-detail {
        font-family: 'Prompt-Regular';
        font-size: 30px;
        line-height: 1.43;
        letter-spacing: 1.35px;
        color: #ffffff;
        margin-top: 75px;
      }
    }
  }
  @media (min-width: ${breakpoint.sm}) and (max-width: ${breakpoint.md}) {
    .icon-button-close {
      position: absolute;
      top: 15px;
      left: 15px;
      width: 40px;
    }
    .image-bg-desktop{
      display: block;
    }
    .image-bg-mobile{
      display: none;
    }
    .content {
      max-width: auto;
      padding: 13% 30px 0 30px;
      .left-block {
        margin-right: 40px;
        .image-icon {
          width: 80px;
        }
      }
      .right-block {
        .text-title {
          font-size: 20px;
        }
        .text-detail {
          font-size: 15px;
          letter-spacing: 1px;
          margin-top: 20px;
        }
      }
    }
  }
  @media (max-width: ${breakpoint.sm}) {
    .icon-button-close {
      position: absolute;
      top: 20px;
      left: 20px;
      width: 50px;
    }
    .image-bg-desktop{
      display: none;
    }
    .image-bg-desktop{
      display: block;
    }
    .content {
      max-width: auto;
      padding: 0 20px 0 20px;
      flex-direction: column;
      margin-top: -3%;
      .left-block {
        margin-right: 50px;
        .image-icon {
          width: 80px;
        }
      }
      .right-block {
        margin-top: 30px;
        .text-title {
          font-size: 24px;
        }
        .text-detail {
          font-size: 15px;
          letter-spacing: 1px;
          margin-top: 15px;
        }
      }
    }
  }
}
.modal-confirm {
  .content {
    position: relative;
    height: 100%;
    z-index: 1;
    padding-top: 11%;
    transform: scale(0.7);
    .topic {
      font-family: 'Prompt-Medium';
      font-size: 40px;
      line-height: 1.5;
      letter-spacing: 0.88px;
      text-align: center;
      color: #ffffff;
    }
    .button-block {
      margin-top: 120px;
      display: flex;
      justify-content: center;
      button {
        width: 295px;
      }
      .button-ok{
        margin-right: 52px;
      }
    }
  }
  @media (min-width: ${breakpoint.sm}) and (max-width: ${breakpoint.md}) {
    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: auto;
      padding: 0 20px 0 20px;
      .topic {
        font-size: 20px;
      }
      .button-block {
        display: flex;
        align-items: center;
        margin-top: 30px;
        button {
          width: 200px;
        }
        .button-ok{
          margin-right: 20px;
        }
      }
    }
  }
  @media (max-width: ${breakpoint.sm}) {
    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: auto;
      padding: 0 20px 0 20px;
      .topic {
        font-size: 22px;
      }
      .button-block {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 50px;
        .button-ok{
          margin-right: 0 !important;
        }
        .button-cancel {
          margin-top: 20px;
        }
      }
    }
  }
}

`;

export default GlobalStyle;
