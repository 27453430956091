import { useEffect, useState } from 'react';

export const useOrientation = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', screenChange);
    return () => window.removeEventListener('resize', screenChange);
  }, []);

  const screenChange = () => {
    setScreenWidth(window.innerWidth);
  };

  return {
    screenWidth,
  };
};
