import styled from 'styled-components';

import { breakpoint } from 'assets/theme';

export default styled.div`
  .description-page {
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: center;
    .main-menu-block {
      position: absolute;
      bottom: 30px;
      left: 30px;
    }
    .bg-image {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .content {
      position: relative;
      z-index: 2;
      max-width: 1327px;
      display: flex;
      justify-content: space-around;
      transform-origin: top;
      .left-block {
        margin-top: 140px;
        margin-right: 88px;
        .title-block {
          position: relative;
          border: 5px solid #ffffff;
          width: 356px;
          height: 356px;
          display: flex;
          align-items: flex-end;
          background-color: rgb(0, 60, 223);
          .head-prop-icon {
            position: absolute;
            top: 8%;
            right: -12%;
            width: 110px;
          }
          h1 {
            font-family: 'Prompt-SemiBold';
            line-height: 1.3;
            font-size: 50px;
            color: #ffffff;
            padding: 0 40px 20px 30px;
          }
        }
        .text-title2 {
          font-family: 'Prompt-Regular';
          font-size: 30px;
          color: #ffffff;
          line-height: 1.43;
          letter-spacing: 0.6px;
          margin-top: 40px;
        }
      }
      .right-block {
        margin-top: 250px;
        .topic-step2 {
          font-family: 'Prompt-Medium';
          font-size: 40px;
          line-height: 1;
          letter-spacing: 0.88px;
          color: #ffffff;
          margin-bottom: 80px;
        }
        .text-description {
          font-family: 'Prompt-Regular';
          font-size: 30px;
          line-height: 1.43;
          letter-spacing: 1.05px;
          color: #ffffff;
        }
        .aptitude-container {
          display: grid;
          grid-template-columns: auto auto;
          margin-top: 62px;
          .aptitude-block {
            flex-basis: 50%;
            display: flex;
            align-items: flex-end;
            margin-bottom: 27px;
            .icon-block {
              margin-right: 34px;
            }
            .name-block {
              font-family: 'Prompt-Regular';
              font-size: 30px;
              line-height: 1.2;
              letter-spacing: 0.75px;
              color: #ffffff;
              white-space: pre-wrap;
            }
          }
        }
        .button-block {
          position: fixed;
          left: 35%;
          bottom: -29%;
        }
      }
    }
  }
  @media (min-width: ${breakpoint.sm}) and (max-width: ${breakpoint.md}) {
    .description-page {
      height: auto;
      min-height: 100vh;
      .main-menu-block {
        position: absolute;
        bottom: 20px;
        left: 20px;
        z-index: 3;
      }
      .content {
        max-width: auto;
        display: flex;
        justify-content: space-around;
        padding: 20px 20px 120px 20px;
        .left-block {
          margin-top: 30px;
          margin-right: 30px;
          .title-block {
            border: 5px solid #ffffff;
            width: 200px;
            height: 200px;
            .head-prop-icon {
              position: absolute;
              top: 5%;
              right: -15%;
              width: 45%;
            }
            h1 {
              font-size: 24px;
              padding: 20px;
            }
          }
          .text-title2 {
            font-size: 18px;
            color: #ffffff;
            margin-top: 20px;
          }
        }
        .right-block {
          margin-top: 50px;
          .topic-step2 {
            font-size: 16px;
            margin-bottom: 20px;
          }
          .text-description {
            font-size: 16px;
          }
          .aptitude-container {
            /* display: flex;
            flex-direction: column; */
            grid-template-columns: auto;
            margin-top: 30px;
            .aptitude-block {
              flex-basis: auto;
              margin-bottom: 15px;
              .icon-block {
                margin-right: 25px;
                img {
                  width: 50px;
                }
              }
              .name-block {
                font-size: 16px;
              }
            }
          }
          .button-block {
            position: relative;
            left: auto;
            bottom: auto;
            transform: none;
            text-align: center;
            margin-top: 30px;
          }
        }
      }
    }
  }
  @media (max-width: ${breakpoint.sm}) {
    .description-page {
      height: auto;
      min-height: 100vh;
      .main-menu-block {
        position: absolute;
        bottom: 20px;
        left: 20px;
        z-index: 3;
      }
      .content {
        max-width: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        padding: 20px 20px 120px 20px;
        .left-block {
          margin-top: 30px;
          margin-right: 30px;
          .title-block {
            border: 5px solid #ffffff;
            width: 250px;
            height: 250px;
            .head-prop-icon {
              position: absolute;
              top: 5%;
              right: -15%;
              width: 45%;
            }
            h1 {
              font-size: 30px;
              padding: 20px;
            }
          }
          .text-title2 {
            font-size: 20px;
            color: #ffffff;
            margin-top: 20px;
          }
        }
        .right-block {
          margin-top: 40px;
          .topic-step2 {
            font-size: 20px;
            margin-bottom: 20px;
          }
          .text-description {
            font-size: 16px;
          }
          .aptitude-container {
            grid-template-columns: auto;
            margin-top: 30px;
            .aptitude-block {
              flex: 1;
              flex-basis: auto;
              margin-bottom: 15px;
              .icon-block {
                margin-right: 25px;
                img {
                  width: 50px;
                }
              }
              .name-block {
                font-size: 16px;
              }
            }
          }
          .button-block {
            position: relative;
            left: auto;
            bottom: auto;
            transform: none;
            text-align: center;
            margin-top: 30px;
          }
        }
      }
    }
  }
`;
