import React from 'react';

import 'assets/css/index.scss';
import GlobalStyle from 'assets/css/global';

import Main from 'pages/Main';
// import Question from 'container/Question';

import { useProcess } from 'hooks/process';

export default function App() {
  useProcess();
  return (
    <>
      <GlobalStyle />
      <Main />
      {/* <Question /> */}
    </>
  );
}
