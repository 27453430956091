const theme = {
  primary: '#03173c',
};

const breakpoint = {
  xs: 0,
  sm: '575.98px',
  // md: '767.98px',
  md: '820px',
  lg: '991.98px',
  xl: '1400px',
  xxl: '1600px',
};

export default theme;
export { breakpoint };
