import { motion } from 'framer-motion';

import { Button, FloatInSquare } from 'components';

import Styled from './styles.js';

const bg = `${process.env.PUBLIC_URL}/assets/images/bg.jpg`;
const headpropIcon = `${process.env.PUBLIC_URL}/assets/images/head-prop.png`;
const icon1 = `${process.env.PUBLIC_URL}/assets/images/intro/icon-1.png`;
const icon2 = `${process.env.PUBLIC_URL}/assets/images/intro/icon-2.png`;
const icon3 = `${process.env.PUBLIC_URL}/assets/images/intro/icon-3.png`;
const icon4 = `${process.env.PUBLIC_URL}/assets/images/intro/icon-4.png`;
const icon5 = `${process.env.PUBLIC_URL}/assets/images/intro/icon-5.png`;
const icon6 = `${process.env.PUBLIC_URL}/assets/images/intro/icon-6.png`;
const icon7 = `${process.env.PUBLIC_URL}/assets/images/intro/icon-7.png`;
const icon8 = `${process.env.PUBLIC_URL}/assets/images/intro/icon-8.png`;
const bg1 = `${process.env.PUBLIC_URL}/assets/images/intro/prop-bg-1.png`;
const bg2 = `${process.env.PUBLIC_URL}/assets/images/intro/prop-bg-2.png`;
const bg3 = `${process.env.PUBLIC_URL}/assets/images/intro/prop-bg-3.png`;
const bg4 = `${process.env.PUBLIC_URL}/assets/images/intro/prop-bg-4.png`;
const bg5 = `${process.env.PUBLIC_URL}/assets/images/intro/prop-bg-5.png`;
const bg6 = `${process.env.PUBLIC_URL}/assets/images/intro/prop-bg-6.png`;
const bg7 = `${process.env.PUBLIC_URL}/assets/images/intro/prop-bg-7.png`;
const bg8 = `${process.env.PUBLIC_URL}/assets/images/intro/prop-bg-8.png`;
const bg9 = `${process.env.PUBLIC_URL}/assets/images/intro/prop-bg-9.png`;
const bg10 = `${process.env.PUBLIC_URL}/assets/images/intro/prop-bg-10.png`;
const bg11 = `${process.env.PUBLIC_URL}/assets/images/intro/prop-bg-11.png`;
const bg12 = `${process.env.PUBLIC_URL}/assets/images/intro/prop-bg-12.png`;
const bg13 = `${process.env.PUBLIC_URL}/assets/images/intro/prop-bg-13.png`;
const bg14 = `${process.env.PUBLIC_URL}/assets/images/intro/prop-bg-14.png`;
const bg15 = `${process.env.PUBLIC_URL}/assets/images/intro/prop-bg-15.png`;
const bg16 = `${process.env.PUBLIC_URL}/assets/images/intro/prop-bg-16.png`;
const bg17 = `${process.env.PUBLIC_URL}/assets/images/intro/prop-bg-17.png`;
const bg18 = `${process.env.PUBLIC_URL}/assets/images/intro/prop-bg-18.png`;
const bg19 = `${process.env.PUBLIC_URL}/assets/images/intro/prop-bg-19.png`;
const line = `${process.env.PUBLIC_URL}/assets/images/intro/prop-bg-20.png`;

export default function Intro({ onNext = () => null }) {
  return (
    <Styled>
      <div className="intro-page">
        <img className="bg-image" src={bg} alt="" />
        <FloatInSquare className="bg1" image={bg1} margin={40} />
        <motion.img
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ delay: 1, duration: 0.6 }}
          className="bg2"
          src={bg2}
          alt=""
        />
        <motion.img
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ delay: 1.3, duration: 0.6 }}
          className="bg3"
          src={bg3}
          alt=""
        />
        <FloatInSquare className="bg4" image={bg4} />
        <FloatInSquare className="bg5" image={bg5} margin={40} />
        <FloatInSquare className="bg6" image={bg6} margin={40} />
        <FloatInSquare className="bg7" image={bg7} />
        <motion.img
          initial={{ scale: 0 }}
          animate={{ scale: 0.7 }}
          transition={{ delay: 1, duration: 0.6 }}
          className="bg8"
          src={bg8}
          alt=""
        />
        <motion.img
          initial={{ scale: 0 }}
          animate={{ scale: 0.7 }}
          transition={{ delay: 1.3, duration: 0.6 }}
          className="bg9"
          src={bg9}
          alt=""
        />
        <motion.img
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ delay: 1, duration: 0.6 }}
          className="bg10"
          src={bg10}
          alt=""
        />
        <motion.img
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ delay: 1.3, duration: 0.6 }}
          className="bg11"
          src={bg11}
          alt=""
        />
        <motion.img
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ delay: 1.6, duration: 0.6 }}
          className="bg12"
          src={bg12}
          alt=""
        />
        <FloatInSquare className="bg13" image={bg13} />
        <FloatInSquare className="bg14" image={bg14} margin={40} />
        <FloatInSquare className="bg15" image={bg15} margin={40} />
        <FloatInSquare className="bg16" image={bg16} margin={40} />
        <FloatInSquare className="bg17" image={bg17} />
        <FloatInSquare className="bg18" image={bg18} />
        <FloatInSquare className="bg19" image={bg19} />
        <img className="line1" src={line} alt="" />
        <img className="line2" src={line} alt="" />
        <img className="line3" src={line} alt="" />
        <img className="icon1" src={icon1} alt="" />
        <img className="icon2" src={icon2} alt="" />
        <img className="icon3" src={icon3} alt="" />
        <img className="icon4" src={icon4} alt="" />
        <img className="icon5" src={icon5} alt="" />
        <img className="icon6" src={icon6} alt="" />
        <img className="icon7" src={icon7} alt="" />
        <img className="icon8" src={icon8} alt="" />
        <div className="content">
          <motion.div
            initial={{ scaleX: 0 }}
            animate={{ scaleX: 1 }}
            transition={{ delay: 0, duration: 0.6 }}
            className="title-block"
          >
            <motion.img
              initial={{ scaleX: 0 }}
              animate={{ scaleX: 1 }}
              transition={{ delay: 0.6, duration: 0.6 }}
              className="head-prop-icon"
              src={headpropIcon}
              alt=""
            />
            <motion.h1
              initial={{ x: 150, y: -150, scale: 0 }}
              animate={{ x: 0, y: 0, scale: 1 }}
              transition={{ delay: 0.7, duration: 0.6 }}
            >
              ทดสอบความถนัดของคุณ
            </motion.h1>
          </motion.div>
          <motion.p
            initial={{ y: -150, scale: 0 }}
            animate={{ y: 0, scale: 1 }}
            transition={{ delay: 0.8, duration: 0.6 }}
            className="text-title2"
          >
            เพื่อร่วมเป็นส่วนหนึ่ง
            <br />
            ของคาราวานไปด้วยกัน
          </motion.p>
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ delay: 1, duration: 0.6 }}
            className="button-block"
          >
            <Button onClick={onNext}>เริ่ม</Button>
          </motion.div>
        </div>
      </div>
    </Styled>
  );
}
