import Modal from 'react-modal';

import { Button } from 'components';

const bg = `${process.env.PUBLIC_URL}/assets/images/bg-send-mail.jpg`;

// Modal.setAppElement('#root');

export default function ModalConfirm({
  isOpen = false,
  onClose = () => null,
  onConfirm = () => null,
}) {
  return (
    <Modal
      isOpen={isOpen}
      closeTimeoutMS={300}
      onRequestClose={onClose}
      className="modal-confirm"
    >
      <img className="image-bg" src={bg} alt="" />
      <div className="content">
        <p className="topic">
          ถ้าออกจากหน้านี้แล้วแบบสอบถามจะไม่แสดงผลนะ
          <br />
          ต้องการออกจากหน้านี้ใช่ไหม
        </p>
        <div className="button-block">
          <div className="button-ok">
            <Button
              style={{
                backgroundImage: 'linear-gradient(#079DFF, #0793FF)',
              }}
              onClick={onConfirm}
            >
              ยืนยัน
            </Button>
          </div>
          <div className="button-cancel">
            <Button ghost onClick={onClose}>
              ยกเลิก
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
