import styled from 'styled-components';
import { motion } from 'framer-motion';

import { breakpoint } from 'assets/theme';

export default styled(motion.div)`
  button {
    width: 453px;
    min-height: 85px;
    background-color: transparent;
    background-image: ${(props) =>
      props.ghost
        ? 'none'
        : 'linear-gradient(rgb(3, 100, 255), rgb(3, 83, 255))'};
    outline: none;
    border: none;
    font-family: 'Prompt-Regular';
    font-size: 30px;
    line-height: 1;
    color: #ffffff;
    text-align: center;
    .inner-block {
      padding: 16px;
      padding: ${(props) => (props.ghost ? '23px' : '16px')};
      border: 4px solid rgb(0, 185, 255);
    }
  }
  /* @media (min-width: ${breakpoint.sm}) and (max-width: ${breakpoint.md}) {
  } */
  @media (max-width: ${breakpoint.md}) {
    button {
      width: 250px;
      min-height: 55px;
      font-size: 20px;
      padding: ${(props) => (props.ghost ? '0' : '5px')};
      .inner-block {
        padding: ${(props) => (props.ghost ? '13px' : '14px')};
        border: 2px solid rgb(0, 185, 255);
      }
    }
  }
`;
