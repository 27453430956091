import { motion } from 'framer-motion';

import { randomNumberBetween } from 'utils';

import Styled from './styles.js';

export default function FloatInSquare({
  image,
  margin = 30,
  duration = 20,
  ...props
}) {
  return (
    <Styled {...props}>
      {image && (
        <motion.img
          animate={{
            x: [
              0,
              randomNumberBetween(-margin, margin),
              randomNumberBetween(-margin, margin),
              randomNumberBetween(-margin, margin),
              randomNumberBetween(-margin, margin),
            ],
            y: [
              0,
              randomNumberBetween(-margin, margin),
              randomNumberBetween(-margin, margin),
              randomNumberBetween(-margin, margin),
              randomNumberBetween(-margin, margin),
            ],
          }}
          transition={{
            ease: 'linear',
            repeat: Infinity,
            repeatType: 'reverse',
            duration,
          }}
          src={image}
          alt=""
        />
      )}
    </Styled>
  );
}
